<template>
  <div class="home">
    <!-- TOP-BANNER START -->
    <section class="top_banner py-0 d-flex align-items-center">
      <div class="container-fluid">
        <div class="
            row
            align-items-center
            flex-md-row flex-column-reverse
            justify-content-center
          ">
          <div class="col-xl-5 col-md-7 col-sm-7">
            <!-- BANNER TEXT -->
            <div class="banner-text text-center text-md-start">
              <h1 class="mb-3">
                Investinos is India's Most Trusted <br class="d-none" />Bitcoin
                & Cryptocurrency <br class="d-none d-lg-block" />Exchange
              </h1>
              <p>
                Buy, Sell & Trade Bitcoin, Ethereum, Ripple, Litecoin and more
                cryptocurrencies <br class="d-none d-xxl-block" />in India.
                Start trading now!
              </p>
            </div>
            <div class="inv_btn text-center text-md-start" v-if="!login">
              <button class="btn btn-light fw-bold">
                <router-link to="/register" style="color: inherit; text-decoration: none">Register Now</router-link>
              </button>
            </div>
          </div>
          <div class="col-xl-6 col-md-5 text-center">
            <!-- BANNER IMAGE -->
            <div class="banner-image mb-3">
              <img src="@/assets/images/home_images/top-banner/pc.png" class="img-fluid" alt="pc" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- TOP-BANNER END -->
    <!-- BANNER BOTTOM START -->
    <section class="banner_bottom pt-3 pt-md-0">
      <div class="container">
        <div class="row justify-content-center">
          <!-- SLIDER -->
          <div class="col-md-12">
            <div class="section-slider mb-3">
              <swiper :autoplay="{ delay: 2500, disableOnInteraction: false }" :loop="true" @swiper="onSwiper"
                @slideChange="onSlideChange" :slidesPerView="4" :spaceBetween="30" :grabCursor="true" :navigation="true"
                :pagination="false" :modules="modules" :breakpoints="{
                  '320': {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  '500': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  '768': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  '991': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  '1024': {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  '1200': {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }" class="mySwiper">
                <swiper-slide v-for="(curncy, index) in currency_data" :key="index">
                  <div class="box px-3 py-4 align-items-center">
                    <ul class="
                        list-group list-group-horizontal
                        justify-content-between
                      ">
                      <li class="list-group-item border-0">
                        <h6 class="fw-bold">{{ curncy.name }}</h6>
                        <p class="mb-0">{{ curncy.currency }}</p>
                      </li>
                      <li class="list-group-item border-0">
                        <p class="price mb-0">
                          ${{ parseFloat(curncy.price) }}
                        </p>
                        <p class="text-danger change mb-0">
                          {{ curncy.change }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
        <!-- CARDS ROW-->
        <div class="row justify-content-center">
          <div class="col-md-12">
            <!-- CARD SLIDER -->
            <div class="card_slider">
              <swiper :loop="true" :slidesPerView="3" :spaceBetween="30" :grabCursor="true" :pagination="{
                clickable: false,
              }" :modules="modules" :breakpoints="{
                '320': {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                '768': {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                '991': {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                '1024': {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                '1200': {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }" class="mySwiper">
                <swiper-slide v-for="banner in banners" :key="banner.id"><img :src="banner.image" class="img-fluid"
                    alt="" /></swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
        <!-- TABS ROW -->
        <div class="row justify-content-center">
          <div class="col-xl-10 col-md-10">
            <!-- TAB LIST -->
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active fw-bold" id="pills-all-tab" data-bs-toggle="pill"
                  data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">
                  All
                </button>
              </li>
              <!-- <li class="nav-item" role="presentation">
                <button
                  class="nav-link fw-bold"
                  id="pills-coins-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-coins"
                  type="button"
                  role="tab"
                  aria-controls="pills-coins"
                  aria-selected="false"
                >
                  New Coins
                </button>
              </li> -->
              <!--                <li class="nav-item" role="presentation">-->
              <!--                  <button class="nav-link fw-bold" id="pills-gainers-tab" data-bs-toggle="pill" data-bs-target="#pills-gainers" type="button" role="tab" aria-controls="pills-gainers" aria-selected="false">Top Gainers</button>-->
              <!--                </li>-->
              <!--                 <li class="nav-item" role="presentation">-->
              <!--                  <button class="nav-link fw-bold" id="pills-month-tab" data-bs-toggle="pill" data-bs-target="#pills-month" type="button" role="tab" aria-controls="pills-month" aria-selected="false">Last Month</button>-->
              <!--                </li>-->
            </ul>
            <!-- TAB CONTENT -->
            <div class="tab-content" id="pills-tabContent">
              <!-- ALL TAB CONTENT  -->
              <div class="tab-pane show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                <div class="list_table table-responsive">
                  <table class="table table-borderless text-center align-middle">
                    <thead>
                      <tr>
                        <th scope="col">Currency</th>
                        <th scope="col">Last Price</th>
                        <th scope="col">24h Change</th>
                        <th scope="col">Markets</th>
                        <th scope="col">Buy</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(currency, index) in currency_data" :key="index">
                        <td class="currency">{{ currency.currency }}</td>
                        <td class="price">${{ parseFloat(currency.price) }}</td>
                        <td class="change">{{ currency.change }}</td>
                        <td class="markets">
                          <img src="@/assets/images/home_images/banner-bottom/market.png" class="img-fluid" alt="" />
                        </td>
                        <td class="inv_btn">
                          <router-link to="" class="btn px-3 py-1">Buy</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- COINS TAB CONTENT -->
              <div class="tab-pane" id="pills-coins" role="tabpanel" aria-labelledby="pills-coins-tab">
                <div class="table-responsive list_table">
                  <table class="
                      table table-borderless
                      text-center
                      table-responsive
                      align-middle
                    ">
                    <thead>
                      <tr>
                        <th scope="col">Currency</th>
                        <th scope="col">Last Price</th>
                        <th scope="col">24h Change</th>
                        <th scope="col">Markets</th>
                        <th scope="col">Buy</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(coin, index) in CoinData" :key="index">
                        <td class="currency">{{ coin.currency }}</td>
                        <td class="price">{{ coin.price }}</td>
                        <td class="change">{{ coin.change }}</td>
                        <td class="markets">
                          <img src="@/assets/images/home_images/banner-bottom/market.png" class="img-fluid" alt="" />
                        </td>
                        <td class="inv_btn">
                          <router-link to="" class="btn px-3 py-1">Buy</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- GAINERS TAB CONTENT -->
              <div class="tab-pane" id="pills-gainers" role="tabpanel" aria-labelledby="pills-gainers-tab">
                <div class="list_table table-responsive">
                  <table class="
                      table table-borderless
                      text-center
                      table-responsive
                      align-middle
                    ">
                    <thead>
                      <tr>
                        <th scope="col">Currency</th>
                        <th scope="col">Last Price</th>
                        <th scope="col">24h Change</th>
                        <th scope="col">Markets</th>
                        <th scope="col">Buy</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(gainer, index) in GainerData" :key="index">
                        <td class="currency">{{ gainer.currency }}</td>
                        <td class="price">{{ gainer.price }}</td>
                        <td class="change">{{ gainer.change }}</td>
                        <td class="markets">
                          <img src="@/assets/images/home_images/banner-bottom/market.png" class="img-fluid" alt="" />
                        </td>
                        <td class="inv_btn">
                          <router-link to="" class="btn px-3 py-1">Buy</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- MONTH TAB CONTENT -->
              <div class="tab-pane" id="pills-month" role="tabpanel" aria-labelledby="pills-month-tab">
                <div class="list_table table-responsive">
                  <table class="
                      table table-borderless
                      text-center
                      table-responsive
                      align-middle
                    ">
                    <thead>
                      <tr>
                        <th scope="col">Currency</th>
                        <th scope="col">Last Price</th>
                        <th scope="col">24h Change</th>
                        <th scope="col">Markets</th>
                        <th scope="col">Buy</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(month, index) in MonthData" :key="index">
                        <td class="currency">{{ month.currency }}</td>
                        <td class="price">{{ month.price }}</td>
                        <td class="change">{{ month.change }}</td>
                        <td class="markets">
                          <img src="@/assets/images/home_images/banner-bottom/market.png" class="img-fluid" alt="" />
                        </td>
                        <td class="inv_btn">
                          <router-link to="" class="btn px-3 py-1">Buy</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!--  BUTTON -->
          <div class="col-md-12 text-center">
            <div class="button inv_btn">
              <router-link to="" class="inv_btn btn px-4">View All</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- BANNER-BOTTOM-END -->
    <!-- INTRODUCTION START -->
    <section class="introduction">
      <div class="container">
        <MainHeading main_heading="INTRODUCING INVESTINOS" />
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-row col-md-6">
            <div class="section-image mb-5 mb-md-0">
              <img src="@/assets/images/home_images/introduce/intro-img.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="intro_top">
              <p>
                <sup>J</sup>ust like Bitcoin and other cryptocurrencies today,
                Investinos is a token created by Investinos. There will be a
                maximum of 1 Billion Investinos coins ever created.
              </p>
            </div>
            <div class="intro_bottom mt-2">
              <p>
                Investinos, a utility token backed by Investinos, forms the
                backbone of Investinos ecosystem. We launched Investinos tokens
                to involve our community in helping us build out Investinos, and
                reward them accordingly for contributing to our success. This
                helps us stay true to the ethos of cryptocurrency and blockchain
                - to share the rewards of Investinos success with our early
                adopters and supporters.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- INTRODUCTION END -->
    <!-- CHOOSE US START -->
    <section class="choose_us">
      <div class="container">
        <MainHeading main_heading="WHY CHOOSE US?" />
        <div class="row justify-content-center">
          <div class="col-xl-4 col-md-5">
            <div class="section_content text-center mb-2 mb-lg-5">
              <div class="icon mb-3">
                <img src="@/assets/images/home_images/choose/capability.png" class="img-fluid" alt="" />
              </div>
              <h4>
                Strong, Independent R&D
                <br class="d-none d-lg-block" />Capabilities
              </h4>
              <div class="paragraph">
                <p>
                  Self-developed third-gen trading system from 0 to 1.Exclusive
                  features to give you the ultimate trading experience without
                  fail.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-xl-4 col-md-5">
            <div class="section_content text-center mb-2 mb-lg-5">
              <div class="icon mb-3">
                <img src="@/assets/images/home_images/choose/risk.png" class="img-fluid" alt="risk" />
              </div>
              <h4>
                Fortified Risk Control <br />
                Systems
              </h4>
              <div class="paragraph">
                <p>
                  Dual-price liquidation protection mechanism to protect highly
                  leveraged traders from incurring significant losses.
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-2">
            <div class="investinos-image text-center mb-4 mb-lg-5">
              <img src="@/assets/logo.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="col-xl-4 col-md-5">
            <div class="section_content text-center mb-2 mb-lg-5">
              <div class="icon mb-3">
                <img src="@/assets/images/home_images/choose/security.png" class="img-fluid" alt="security" />
              </div>
              <h4>Top <br />Security</h4>
              <div class="paragraph">
                <p>
                  Hot and cold wallet structure secures assets in isolated
                  methods Dual means to ensure the safety of your assets.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-xl-4 col-md-5">
            <div class="section_content text-center mb-2 mb-lg-5">
              <div class="icon mb-3">
                <img src="@/assets/images/home_images/choose/cashflow.png" class="img-fluid" alt="cashflow" />
              </div>
              <h4>Fast <br />Transactions</h4>
              <div class="paragraph">
                <p>
                  Our services will leave you astonished as we are committed to
                  you! Switching to a fully automated process, your time will be
                  utilized like nowhere.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- CHOOSE US END -->
    <!-- DOWNLOAD APP START -->
    <section class="download_app">
      <div class="container">
        <MainHeading main_heading="DOWNLOAD OUR APP NOW." />
        <div class="
            row
            align-items-center
            justify-content-between
            flex-column-reverse flex-md-row
          ">
          <div class="col-xl-5 col-md-7">
            <!-- SECTION CONTENT START -->
            <div class="section_content position-relative">
              <h4 class="fw-bold">Ease of Use</h4>
              <p>
                We embed new technologies in our app that were carefully
                evaluated according to the criteria of cost and ease of use.
              </p>
            </div>
            <div class="section_content position-relative">
              <h4 class="fw-bold">Transfer Securely</h4>
              <p>
                Our app includes a network protocol which establishes an
                encrypted tunnel allowing to log in to a computer and making
                data transfer safe.
              </p>
            </div>
            <div class="section_content position-relative">
              <h4 class="fw-bold">Greater Diversity</h4>
              <p>
                We created our app with all the necessary functions, which
                helped improve the control over operations and speed up the data
                exchange process.
              </p>
            </div>
            <!-- SECTION CONTENT END -->
            <!-- BUTTON -->
            <div class="download_btn d-flex">
              <a href=""><img src="@/assets/images/home_images/download/app-store.png" class="img-fluid me-2"
                  alt="app-store" /></a>
              <a href=""><img src="@/assets/images/home_images/download/play-store.png" class="img-fluid me-2"
                  alt="play-store" /></a>
            </div>
          </div>
          <div class="col-xl-6 col-md-5">
            <!-- MOBILE IMAGE -->
            <div class="mobile_image text-center position-relative">
              <img src="@/assets/images/home_images/download/mobile.png" class="img-fluid" alt="mobile" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- DOWNLOAD APP END -->
    <!-- ASKED QUES START -->
    <section class="asked_ques">
      <div class="container">
        <MainHeading main_heading="FREQUENTLY ASKED QUESTIONS." />
        <div class="row justify-content-center">
          <div class="col-xl-10 col-md-12">
            <!-- ACCORDION -->
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button fw-bold" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Q1 &nbsp; Who manages the platform?
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      A1 &nbsp; Investinos does not have manager.This means that
                      theplatform is fully decentralized , it has no leaders and
                      mins
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Q2 &nbsp; Are the interest payments guaranteed even when the
                    market is down?
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      A2 &nbsp; Investinos does not have manager.This means that
                      theplatform is fully decentralized , it has no leaders and
                      mins
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Q3 &nbsp; What happens to my funds on the redemption date?
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      A3 &nbsp; Investinos does not have manager.This means that
                      theplatform is fully decentralized , it has no leaders and
                      mins
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Q3 &nbsp; What happens to my funds on the redemption date?
                  </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      A4 &nbsp; Investinos does not have manager.This means that
                      theplatform is fully decentralized , it has no leaders and
                      mins
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ASKED QUES START -->
    <!-- SUPPORT TEAM START -->
    <section class="support_team">
      <div class="container">
        <!-- MAIN HEADING -->
        <MainHeading main_heading="GET HELP FROM A 24/7 GLOBAL SUPPORT TEAM." />
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-5 col-md-5">
            <!-- TEAM IMAGE -->
            <div class="team_image text-center position-relative mb-3">
              <img src="@/assets/images/home_images/support/team.png" class="img-fluid" alt="support" />
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <!-- TEAM TEXT -->
            <div class="paragraph mb-3 mb-xl-5">
              <p>
                We pair our global 24/7/365 live chat with an extensive Support
                Center to help ensure your questions are answered and your needs
                are met around the clock -- no matter who or where you are.
              </p>
            </div>
            <!-- BUTTON -->
            <div class="inv_btn d-inline-flex">
              <button class="btn text-white">Explore Our Support Center</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- SUPPORT TEAM END -->
  </div>
</template>

<script>
// @ is an alias to /src
// IMPORT HEADING
import MainHeading from "@/components/LandingPage/MainHeading.vue";
// SWIPER
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ApiClass from "@/api/api";

export default {
  name: "HomeView",
  components: {
    MainHeading,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
  data() {
    return {
      banners: [],
      currency_data: [],
      login: false,
      binance_wb: null,
      tickers: [],
    };
  },
  async created() {
    if (localStorage.getItem("token")) {
      this.login = true;
    }
    let data = await ApiClass.getRequest("banner/get", false);
    this.banners = data?.data?.data;

    let data1 = await ApiClass.getNodeRequest("list-crypto/get", false);
    this.currency_data = data1.data.data.USDT.slice(0, 10);
    this.tickers = data1.data.tickers.map((el) => {
      return el.toLowerCase() + "@ticker";
    });
    this.callWebSocket();
  },
  unmounted() {
    this.binance_wb.close();
  },
  methods: {
    callWebSocket() {
      let $this = this;

      $this.binance_wb = new WebSocket(ApiClass.BinanaceSocketUrl);

      // ON OPEN
      $this.binance_wb.onopen = () => {
        $this.binance_wb.send(
          JSON.stringify({
            method: "SUBSCRIBE",
            params: $this.tickers,
            id: 1,
          })
        );
      };

      // ON CLOSE
      $this.binance_wb.onclose = () => {
        console.log("List Crypto Disconnected");
      };

      $this.binance_wb.onmessage = (data) => {
        let list = JSON.parse(data.data);
        // console.log("listing", list);
        $this.currency_data.filter((el) => {
          if (el.symbol == list.s) {
            el.price = list.c;
            el.change = list.P;
          }
        });
      };
    },
  },
};
</script>
<style scoped>
section {
  padding: 70px 0;
}

.list-group {
  border: none;
}

.paragraph p {
  color: var(--inv-ld-lightgrey);
  font-size: 18px;
}

.inv_btn .btn {
  background-color: var(--inv-primary);
  color: var(--inv-white);
}

.inv_btn .btn:hover {
  background-color: var(--inv-fade-primary);
  color: var(--inv-white);
}

/* *********** BANNER START ********* */
.top_banner {
  background-color: var(--inv-primary);
  min-height: 85vh;
  position: relative;
  z-index: 1;
}

.top_banner:before {
  position: absolute;
  content: "";
  background-image: url("@/assets/images/home_images/top-banner/layer-1.png");
  height: 400px;
  width: 400px;
  top: -146px;
  left: 0;
}

.top_banner:after {
  position: absolute;
  content: "";
  background-image: url("@/assets/images/home_images/top-banner/layer-2.png");
  height: 472px;
  width: 364px;
  top: 0;
  right: 2px;
  z-index: -1;
  background-repeat: no-repeat;
}

.top_banner h1 {
  color: var(--inv-white);
}

.top_banner p {
  color: var(--inv-secondary-grey);
  font-size: 18px;
}

.top_banner .btn {
  background-color: var(--inv-white);
  color: var(--black);
}

.btn:focus {
  box-shadow: none !important;
  outline: 0;
}

/* *********** BANNER END ********* */
/* ************BANNER BOTTOM START********** */
.banner_bottom {
  background-color: var(--inv-white);
}

.section-slider {
  top: -60px;
  position: relative;
}

.section-slider .box {
  background-color: var(--inv-white);
}

.banner_bottom .nav-link {
  width: 138px;
  color: var(--inv-primary);
  background-color: var(--inv-white);
  border: 1px solid var(--inv-white);
  margin-right: 10px;
}

.banner_bottom .nav-pills .nav-link.active {
  background-color: var(--inv-primary);
  color: var(--inv-white);
}

.banner_bottom thead tr {
  background-color: var(--inv-primary);
  color: var(--inv-white);
}

.banner_bottom table tbody tr {
  background-color: var(--inv-white);
}

.banner_bottom .list_table {
  border-radius: 8px;
  cursor: pointer;
}

.banner_bottom ul.nav-pills {
  margin-top: 77px;
}

.banner_bottom tbody tr:hover {
  background-color: rgb(13 66 109 / 21%);
  transition: 1s;
}

.banner_bottom .list-group-item {
  background: transparent;
}

.section-slider .box {
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: var(--inv-shadow);
}

/* *************BANNER BOTTOM END********** */
/* ***********INTRODUCTION START********** */
.introduction sup {
  font-size: 42px;
  top: 0;
}

.intro_top {
  border-bottom: 2px solid var(--inv-black);
  border-bottom-style: dashed;
}

.intro_top p {
  color: var(--inv-black);
}

.intro_bottom p {
  color: var(--inv-ld-lightgrey);
  font-size: 15px;
}

/* ***********INTRODUCTION END********* */
/* *********** CHOOSE US START ********* */
.choose_us {
  background-color: var(--inv-white);
  border-bottom: 2px solid var(--inv-primary);
}

/* *********** CHOOSE US END ********* */
/* *************APP DOWNLOAD START*********** */
.section_content h4 {
  font-size: 22px;
}

.download_app .section_content:before {
  position: absolute;
  content: "";
  background-image: url("@/assets/images/home_images/icons/arrow-right.svg");
  width: 64px;
  height: 55px;
  left: -42px;
  top: -29px;
  background-size: contain;
  background-size: cover;
}

.mobile_image {
  z-index: 1;
}

.download_btn img {
  position: relative;
  top: 0;
}

.download_btn img:hover {
  top: -5px;
  transition: 0.5s;
}

/* *************APP DOWNLOAD END*********** */
/* *********** ASKED QUES START ********* */
.asked_ques {
  background-color: var(--inv-primary);
  background-image: url("@/assets/images/home_images/asked-ques/map.png");
  background-repeat: no-repeat;
  background-position: center;
  min-height: 754px;
  display: flex;
  align-items: center;
}

.asked_ques .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: transparent;
}

.asked_ques .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.asked_ques .accordion-item {
  border: none;
  margin-bottom: 10px;
}

.asked_ques .accordion-button {
  font-size: 22px;
  color: var(--inv-black);
}

.asked_ques .accordion-button::after {
  background-image: none;
}

.asked_ques .accordion-button:not(.collapsed) {
  font-size: 19px;
  color: var(--inv-black);
}

.asked_ques .accordion-button:not(.collapsed)::after {
  background-image: none;
}

/* *********** ASKED QUES START ********* */

/* *********** SUPPORT TEAM START ********* */
.support_team {
  background-color: var(--inv-white);
}

.explore_btn {
  background-color: var(--inv-primary);
}

.team_image {
  z-index: 1;
}

/* *********** SUPPORT TEAM END ********* */
@media (max-width: 1024px) {

  .top_banner:before,
  .top_banner:after {
    display: none;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .top_banner {
    min-height: 70vh;
  }
}

@media (min-width: 320px) and (max-width: 767px) {

  .top_banner img,
  .team_image img,
  .mobile_image img {
    max-width: 260px;
  }

  .top_banner {
    min-height: 60vh;
  }

  .banner-text h1 {
    font-size: 23px;
  }

  .section-slider {
    position: unset;
  }

  .top_banner p,
  .paragraph p,
  p {
    font-size: 13px;
  }

  .banner_btn .btn {
    padding: 8px 12px;
    font-size: 14px;
  }

  .section_content h4 {
    font-size: 16px;
  }

  .download_app .section_content:before {
    display: none;
  }

  .asked_ques .accordion-button:not(.collapsed),
  .asked_ques .accordion-button {
    font-size: 14px;
    font-size: 14px;
  }

  .download_btn img {
    max-width: 100px;
  }

  .top_banner:before,
  .top_banner:after {
    display: none;
  }

  .investinos-image img {
    max-width: 192px;
  }

  .asked_ques {
    min-height: 551px;
  }

  .home .banner_bottom .nav-pills {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}
</style>
