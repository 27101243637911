import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    payment_name: null,
    show_upi: null,
    userInfo: null,
    inrPrice: '',
    userFav: [],
    verifyOtpUserDetail: null,
    gauth_setup: false,
    gauth_status: false,
    gauth_secret: null,
    user_email: null,
    matchData: {}

  },
  getters: {
    getmatchData(state) {
      return state.matchData
    },
    getGoogleAuth: state => {
      return state.gauth_status;
    },
    getGauth: state => {
      return state.gauth_setup;
    },
    get_secret: state => {
      return state.gauth_secret;
    },
    clrStorage() {
      return localStorage.clear();
    },
    getUser(state) {
      return state.userInfo;
    },
    getUserFav(state) {
      return state.userFav;
    },
    get_verifyotp_user: state => {
      return state.verifyOtpUserDetail;
    },
    getUserEmail: state => {
      return state.user_email;
    },
    getpayment_name(state) {
      return state.payment_name;
    },
    getshow_upi(state) {
      return state.show_upi;
    },
  },
  mutations: {
    setpayment_name(state, payload) {
      state.payment_name = payload
    },
    setshow_upi(state, payload) {
      state.show_upi = payload
    },
    setmatchData(state, payload) {
      state.matchData = payload
    },
    SET_USER(state, val) {
      state.userInfo = val;
    },
    SET_INR(state, val) {
      state.inrPrice = val;
    },
    RESET(state) {
      state.user = null;
    },
    SET_USER_FAV(state, payload) {
      state.userFav = payload;
    },
    SET_VERIFYOTP_USER(state, payload) {
      state.verifyOtpUserDetail = payload;
    },
    SET_SECRET(state, payload) {
      state.gauth_secret = payload;
    },
    SET_GOOGLE_STATUS(state, payload) {
      state.gauth_status = payload;
    },
    SET_GAUTH(state, payload) {
      state.gauth_setup = payload
    },
    SET_USER_EMAIL(state, payload) {
      state.user_email = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
