import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },

    {
        path: '/exchange',
        name: 'ExchangeView',
        component: () =>
            import ('../views/ExchangeView.vue')
    },

    //___________p2p
    // {
    //     path: '/p2p',
    //     name: 'P2pView',
    //     component: () =>
    //         import('../views/P2pView.vue')
    // },

    // {
    //     path: '/p2p/order/:order_id(\\d+)',
    //     name: 'p2porder',
    //     component: () =>
    //         import('../views/P2P/P2POrder.vue'),
    //     meta: { authOnly: true }
    //     // meta: { hasThemeSwitch: true }
    // },

    // {
    //     path: '/p2p/sellerorder/:order_id(\\d+)',
    //     name: 'SellerOrder',
    //     component: () =>
    //         import('../views/P2P/SellerOrder.vue'),
    //     meta: { authOnly: true }
    // },


    // {
    //     path: '/p2p/USDT-INR',
    //     name: 'P2P',
    //     component: () =>
    //         import('../views/P2pView.vue'),
    //     meta: { authOnly: true }
    //     // meta: { hasThemeSwitch: true }
    // },

    // {
    //     path: '/p2p/sellerordercomplete/:order_id(\\d+)',
    //     name: 'SellerOrderComplete',
    //     component: () =>
    //         import('../views/P2P/SellerOrderComplete.vue'),
    //     meta: { authOnly: true }
    // },

    //________________p2p paisax
    {
        path: '/p2p',
        name: 'P2PView',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/P2P/P2PView.vue')
    },

    {
        path: '/p2pbuymodal',
        name: 'P2PBuyModal',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/P2P/P2PBuyModal.vue')
    },
    {
        path: '/p2psellmodal',
        name: 'P2PSellModal',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/P2P/P2PSellModal.vue')
    },
    //   {
    //     path: '/p2p-order',
    //     name: 'P2pOrderView',
    //     component: () =>
    //       import('../views/Orderhistory/P2pOrderView.vue')
    //   },

    // _____________________________



    {
        path: '/mobile-setting',
        name: 'MobileSettingView',
        component: () =>
            import ('../views/setting/Mobile/MobileSettingView.vue')
    },


    {
        path: '/profile',
        name: 'ProfileView',
        alias: '/setting',
        component: () =>
            import ('../views/setting/ProfileView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/kyc',
        name: 'KycsettingView',
        component: () =>
            import ('../views/setting/KycsettingView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/bank',
        name: 'BankdetailView',
        component: () =>
            import ('../views/setting/BankdetailView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/password',
        name: 'ChangepasswordView',
        component: () =>
            import ('../views/setting/ChangepasswordView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/authentication',
        name: 'TwofactorView',
        component: () =>
            import ('../views/setting/TwofactorView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/fee',
        name: 'FeeView',
        component: () =>
            import ('../views/setting/FeeView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/activitylog',
        name: 'ActivitylogView',
        component: () =>
            import ('../views/setting/ActivitylogView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/transfer',
        name: 'ForextransferView',
        component: () =>
            import ('../views/setting/ForextransferView.vue')
    },

    {
        path: '/referral-link',
        name: 'ReferrallinkView',
        component: () =>
            import ('../views/setting/ReferrallinkView.vue'),
        meta: { authOnly: true }
    },

    {
        path: '/tradingreport',
        name: 'TradingreportView',
        component: () =>
            import ('../views/setting/TradingreportView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/support',
        name: 'SupportView',
        component: () =>
            import ('../views/setting/SupportView.vue'),
        meta: { authOnly: true }
    },


    {
        path: '/portfolio',
        name: 'PortfolioView',
        component: () =>
            import ('../views/PortfolioView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/login',
        name: 'LoginView',
        component: () =>
            import ('../views/AuthView/LoginView.vue')
    },
    {
        path: '/register',
        name: 'RegisterView',
        component: () =>
            import ('../views/AuthView/RegisterView.vue')
    },
    {
        path: '/forgot-password',
        name: 'ForgotPasswordView',
        component: () =>
            import ('../views/AuthView/ForgotPasswordView.vue')
    },
    {
        path: '/verify',
        name: 'VerificationView',
        component: () =>
            import ('../views/AuthView/VerificationView.vue')
    },
    {
        path: "/google-auth",
        name: "GoogleAuthView",
        component: () =>
            import ("../views/AuthView/GoogleAuthView.vue"),
        // meta: { authOnly: true },
    },
    {
        path: '/reset-password',
        name: 'ResetPasswordView',
        component: () =>
            import ('../views/AuthView/ResetPasswordView.vue')
    },
    {
        path: '/verifymail',
        name: 'EmailVerifyView',
        component: () =>
            import ('../views/AuthView/EmailVerifyView.vue')
    },
    {
        path: '/query',
        name: 'QueryOpt',
        component: () =>
            import ('../views/QueryOpt.vue'),
        meta: { authOnly: true }
    },

    {
        path: '/ticketlist',
        name: 'TicketList',
        component: () =>
            import ('../views/TicketList.vue'),
        meta: { authOnly: true }
    },

    {
        path: '/ticketinfo/:id',
        name: 'TicketInfo',
        component: () =>
            import ('../views/TicketInfo.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/register/:referral',
        component: () =>
            import ('../views/AuthView/RegisterView.vue')
    },
    {
        path: '/withdrawhistory',
        name: 'WithdrawHistory',
        component: () =>
            import ('../views/WithdrawHistory.vue'),
        meta: { authOnly: true }
    },

    {
        path: '/deposithistory',
        name: 'DepositHistory',
        component: () =>
            import ('../views/DepositHistory.vue'),
        meta: { authOnly: true }
    },


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
})

function isLoggedIn() {
    return localStorage.getItem('token');
}

router.beforeEach((to, from, next) => {
    console.log('Loading Started');
    if (to.matched.some(record => record.meta.authOnly)) {
        if (!isLoggedIn()) {

            next({
                path: '/login',
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.guestOnly)) {
        if (isLoggedIn()) {
            next({
                path: '/exchange/BTCUSDT',
            })
        } else {
            next()
        }
    } else {
        next();
    }
})

router.afterEach(() => {
    console.log('Loaded');
})

export default router