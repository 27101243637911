<template>
    <div class="row">
        <div class="col-md-12">
            <div class="section-heading mb-5 text-center">
                <h2 class="fw-bold">{{main_heading}}</h2>
            </div>
        </div>
    </div>
</template>
<script>
export default{
   name:'MainHeading',
   props:{
       main_heading:String
   }
}
</script>
<style scoped>
.section-heading h2{
    color:var(--inv-primary);
    font-size:28px;
}
.asked_ques h2{
    color:var(--inv-white);
}
@media (min-width:767px) and (max-width:991px){
    .section-heading h2{
        font-size:25px;
    }
}
@media (min-width:320px) and (max-width:767px){
    .section-heading h2{
        font-size:18px;
    }

}
</style>
