<template>
    <footer>
        <section class="footer py-5 bg-light">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-xl-3 col-md-4 col-sm-6">
                        <div class="logo">
                           <router-link to="/"><img src="../assets/images/home_images/icons/logo.svg" class="img-fluid" alt=""></router-link>
                        </div>
                        <p>Investinos is a safe and simple gateway to build a strong crypto portfolio for everyone.</p>
                        <div class="social-icons mb-2">
                            <ul class="d-flex m-0 p-0 list-unstyled">
                            <li><img src="../assets/images/home_images/icons/facebook.png" class="img-fluid me-2"></li>
                            <li><img src="../assets/images/home_images/icons/twitter.png" class="img-fluid me-2"></li>
                            <li><img src="../assets/images/home_images/icons/telegram.png" class="img-fluid me-2"></li>
                            <li><img src="../assets/images/home_images/icons/linkedin.png" class="img-fluid me-2"></li>
                            </ul>
                        </div>
                        <p>Ⓒ All rights reserved by Investinos</p>
                    </div>
                     <div class="col-xl-2 col-md-2 col-sm-6">
                        <h5 class="fw-bold mb-3">About</h5>
                        <ul class="list-unstyled">
                            <li><router-link to="">Blog</router-link></li>
                            <li><router-link to="">Careers</router-link></li>
                            <li><router-link to="">Media Assets</router-link></li>
                            <li><router-link to="">Announcements</router-link></li>
                            <li><router-link to="">Terms Of Use</router-link></li>
                            <li><router-link to="">Privacy Policy</router-link></li>
                        </ul>
                    </div>
                     <div class="col-xl-2 col-md-2 col-sm-6">
                         <h5 class="fw-bold mb-3">Products</h5>
                          <ul class="list-unstyled">
                              <li><router-link to="">Lend</router-link></li>
                              <li><router-link to="">Support Trade</router-link></li>
                              <li><router-link to="">Futures</router-link></li>
                              <li><router-link to="">Markets</router-link></li>
                              <li><router-link to="">OTC</router-link></li>
                        </ul>
                    </div>
                     <div class="col-xl-3 col-md-2 col-sm-6">
                         <h5 class="fw-bold mb-3">Support</h5>
                          <ul class="list-unstyled">
                              <li><router-link to="">Help Center</router-link></li>
                              <li><router-link to="">Fees</router-link></li>
                              <li><router-link to="">Security</router-link></li>
                              <li><router-link to="">Law Enforcement Request</router-link></li>
                              <li><router-link to="">What is Bitcoin</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </footer>
</template>
<script>
export default{
 name:'MainFooter',
}
</script>
<style scoped>
a{
    text-decoration: none;
}
.footer ul li a{
    color:var(--inv-grey);
}
.footer ul li{
    margin-bottom:8px;
}
.footer ul li a:hover{
    color:var(--inv-primary);
}
.social-icons li{
    position: relative;
    top:0;
}
.social-icons li:hover{
    top:-5px;
    transition: 0.4s;
}
.footer p{
    color:var(--inv-ld-lightgrey);
}
@media (max-width:991px){
    .footer ul li,
    .footer p{
        font-size:13px;
    }
}
</style>
