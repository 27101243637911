<template>
  <header class="header shadow position-sticky top-0">
    <nav class="navbar navbar-expand-lg py-0">
      <div class="container">
        <router-link class="navbar-brand py-2 px-2" to="/"
          ><img
            src="@/assets/images/home_images/icons/logo_white.png"
            class="img-fluid"
            alt=""
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon">
            <img
              src="@/assets/images/home_images/icons/toggle.svg"
              class="img-fluid"
              alt=""
            />
          </span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-between"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mb-2 me-2 mb-lg-0">
            <li class="nav-item" @click="closeToggle">
              <router-link to="/exchange" class="nav-link px-1 px-lg-4"
                >Exchange</router-link
              >
            </li>
            <li class="nav-item" @click="closeToggle">
              <router-link to="/p2p" class="nav-link px-1 px-lg-4">P2P</router-link>
            </li>
            <li class="nav-item" @click="closeToggle" v-if="loggedIn">
              <router-link to="/portfolio" class="nav-link pe-3"
                >Fund</router-link
              >
            </li>
            <li class="nav-item" @click="closeToggle" v-if="loggedIn">
              <router-link to="/setting" class="nav-link pe-3"
                >Settings</router-link
              >
            </li>
          </ul>
          <div class="d-block d-lg-flex gap-lg-3">
            <div class="login mb-2 mb-lg-0" v-if="!loggedIn">
              <router-link to="/login" class="btn login_btn">LOGIN</router-link>
            </div>
            <div class="register mb-2 mb-lg-0" v-if="!loggedIn">
              <router-link to="/register" class="btn signup_btn"
                >REGISTER</router-link
              >
            </div>

            <div class="logout mb-2 mb-lg-0" v-if="loggedIn">
              <button class="btn btn-primary" @click="showlogoutPopup">
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import ApiClass from "../api/api";

export default {
  name: "MainHeader",
  data() {
    return {
      loggedIn: false,
      logoutType: "",
    };
  },
  watch: {
    $route: function () {
      this.loggedIn = localStorage.getItem("token") ? true : false;
    },
  },
  //   mounted() {
  //     this.loggedIn = localStorage.getItem("token") ? true : false;
  //   },
  methods: {
    closeToggle() {
      const menuToggle = document.getElementById("navbarSupportedContent");
      menuToggle.classList.remove("show");
    },
    showlogoutPopup() {
      this.$swal
        .fire({
          title: "Please Confirm",
          text: "Are you sure want to logout ?",
          icon: "warning",
          iconColor: "#0d426d",
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: "#0d426d",
          cancelButtonColor: "#0d426d",
          confirmButtonText: "Logout From This Device",
          cancelButtonText: "Logout From All Devices",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.logoutType = "logout";
            this.logout(this.logoutType);
          } else {
            this.logoutType = "hardlogout";
            this.logout(this.logoutType);
          }
        });
    },
    async logout(logoutType) {
      var res = await ApiClass.deleteRequest(logoutType, true);
      if (res.data.status_code == 1) {
        this.$store.commit("RESET");
        this.$store.getters.clrStorage;
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        location.replace("/exchange");
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
.header {
  z-index: 1030;
  background-color: var(--inv-primary);
}

.nav-item .nav-link {
  color: var(--inv-secondary-grey);
  font-size: 14px;
  border-right: 1px solid var(--inv-secondary-grey);
  position: relative;
}

.navbar-toggler:focus,
.btn:focus {
  box-shadow: none !important;
}

.header .btn {
  font-size: 12px;
  width: 89px;
}

.login_btn {
  background-color: var(--inv-white);
  border: 2px solid var(--inv-white);
  color: var(--inv-black);
}

.login_btn:hover,
.signup_btn {
  border: 2px solid var(--inv-white);
  color: var(--inv-white);
  background-color: transparent;
}

.header .navbar-nav:last-child.nav-link {
  border-right: 0;
}

.header .signup_btn:hover {
  background: var(--inv-white);
  color: var(--inv-black);
}

.header ul a.router-link-active {
  color: var(--inv-white);
}

.header ul a.router-link-active:before {
    background-color: var(--inv-white);
    position: absolute;
    height: 2px;
    width: 74%;
    bottom: 0;
    content: "";
    left: 0;
    right: 0;
    bottom: -12px;
    margin: auto;
}

/* .header .navbar-brand {
    background-color: var(--inv-white);
    box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;
} */

.logout button.btn.btn-primary {
  background-color: var(--inv-white);
  border: 2px solid var(--inv-white);
  color: var(--inv-black);
}

.logout button.btn.btn-primary:focus {
  box-shadow: none;
}
@media all and (min-width:768px) and (max-width:991px)  {
  .header ul a.router-link-active:before {
    /* width: 11%;
    bottom: 0; */
    display: none;
}
}

@media (max-width: 768px) {
  a.router-link-active:before {
    display: none;
  }

  .nav-item .nav-link {
    border-right: none;
  }
  .header ul a.router-link-active:before {
    width: 11%;
    bottom: 0px;
}
}
</style>
