// import Vue from 'vue'
import axios from "axios";
import store from '../store/index.js';
// Vue.use(store)

export default class ApiClass {

    static CAPTCHA_SITE_KEY = '6LdzVqEeAAAAAChGF8T-0UYDpY2bBmSDIF7ebHjH';

    
    // ======= LOCAL LINK =========//
 
    // static nodeUrl = 'http://192.168.11.72:3001/';
    // static Socket = 'http://192.168.11.72:3001/'; 
    // static SocketnodeUrl = 'http://192.168.11.72:3001/'; 
    // static baseUrl = 'http://192.168.11.72:8000/api/';
    // // static baseUrl = 'http://192.168.11.76:8001/api/';
    // static VUE_DOMAIN = 'http://192.168.11.72:8080/';   
    // static BinanaceSocketUrl = 'wss://stream.binance.com:9443/ws';
    

    // ======= LIVE LINKS =========//
 

    static nodeUrl = 'https://nbackend.investinos.com/';

    static SocketnodeUrl = 'wss://nbackend.investinos.com/' 
    static baseUrl = 'https://dbackend.investinos.com/backend/public/api/';
    static VUE_DOMAIN = 'https://demo.investinos.com/';   
    static BinanaceSocketUrl = 'wss://stream.binance.com:9443/ws';


 

    //******************************* Post api *******************************************//


    static postRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        return axios.post(this.baseUrl + apiUrl, formData, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    //******************************* Get api *******************************************//

    static getRequest(apiUrl, isToken = true, headers = null, params = null) {
        return axios.get(this.baseUrl + apiUrl, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    //******************************** Update api ********************************************** */

    //******************* if form data with image ************************ */

    static updateFormRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        baseParam = { "_method": "PUT" }
        if (params != null) {
            // var baseParam = $.extend(params, baseParam)
            var baseParam = Object.assign(params, baseParam)
        }
        return axios.post(this.baseUrl + apiUrl, formData, this.config(isToken, headers, baseParam)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }
    //******************* form data in json format ************************ */

    static updateRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {

        return axios.put(this.baseUrl + apiUrl, formData, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    //*********************************** Delete api *************************************************** */

    static deleteRequest(apiUrl, isToken = true, headers = null, params = null) {
        return axios.delete(this.baseUrl + apiUrl, this.config(isToken, headers, params)).then(result => {
            return result
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        })
    }

    //******************************* Configrations of header and parameters ******************************* */

    static config(isToken = true, headers = null, parameters = null) {
        var defaultHeaders = {
            Accept: "application/json"
        };
        var merge = {};
        if (isToken) {
            var token = { Authorization: "Bearer " + localStorage.getItem("token") }
            // var token = { Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJlbWFpbCI6InJha2VzaEB5b3BtYWlsLmNvbSIsImlhdCI6MTY1ODMwMDg4MywiZXhwIjoxNjU4Mzg3MjgzfQ.Yo9kj9iPIetdGsqtS3b1K5CWSjisNfCVzPlprOUn-9g" }
            // var merge = $.extend(defaultHeaders, token)
            merge = Object.assign(defaultHeaders, token)
        }
        // var merge = $.extend(defaultHeaders, headers)
        merge = Object.assign(defaultHeaders, headers)
        return {
            headers: merge,
            params: parameters
        }
    }

    //********************************* if the unautherntication Error..... ************************************** */

    static unauthenticateRedirect() {
        localStorage.clear()
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        store.commit('SET_USER', null);
        location.replace('/login');
        // this.toastMessage("error", "You are currently blocked by the admin or try to login again");
    }

    // node Api 

    static getNodeRequest(apiUrl, isToken = true, headers = null, params = null) {
        return axios.get(this.nodeUrl + apiUrl, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }
    // Post
    static postNodeRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        return axios.post(this.nodeUrl + apiUrl, formData, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

}