import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//BOOTSTRAP
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment';

// Skeleter Loader
import { Skeletor } from "vue-skeletor";

const mixins={
    methods: {
        toastMessage(type, msg) {
            this.$swal({
                toast: true,
                position: "bottom-end",
                icon: type.toLowerCase(),
                title: msg,
                showConfirmButton: false,
                timer: 3000,
            });
        },
        copyCode(dref) {
            this.$refs[dref].focus();
            document.execCommand('copy');
        },

        format_date(value) {
            if (value) {
                return moment(String(value)).format('MM-DD-YYYY,hh:mm:ss a')
            }
        },
    }
};


createApp(App).component(Skeletor.name, Skeletor).use(store).use(router).use(VueSweetalert2).mixin(mixins).mount('#app')