<template>
  <MainHeader />
  <router-view />
  <MainFooter v-if="show" />
</template>

<script>
// IMPORT HEADER
import MainHeader from "@/components/MainHeader.vue";
// IMPORT FOOTER
import MainFooter from "@/components/MainFooter.vue";
export default {
  name: "AppView",
  components: {
    MainHeader,
    MainFooter,
  },
  data(){
    return {
      show:true
    }
  },




   watch: {
    $route: function (v) {
      this.show= ((v.path=='/exchange')) ? false : true
    },
  },
  
};
</script>

<style>
#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Montserrat;
  src: url(../src/assets/font-family/Montserrat-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url(../src/assets/font-family/Montserrat-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Montserrat;
  src: url(../src/assets/font-family/Montserrat-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url(../src/assets/font-family/Montserrat-Regular.ttf);
  font-weight: 400;
}

body {
  font-family: Montserrat !important;
}

:root {
  --inv-secondary-grey: #c9c3c3;
  --inv-ld-grey: grey;
  --inv-ld-lightgrey: #959393;
  --inv-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  --inv-portfolio-blue: #cfeaff;
  --inv-primary: #0d426d;
  --inv-light-grey: #f0f0f0;
  --inv-portfolio-grey: #ececec;
  --inv-auth-blue: rgb(13 110 253 / 19%);
  --inv-fade-primary: #0d426dc9;
  --inv-black: #000;
  --inv-white: #fff;
  --inv-grey: #7c7c7c;
  --inv-light-grey: #e1e1e1;
  --inv-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  --inv-pills-shadow: rgb(13 66 109) 0px 1px 4px;
  --inv-green: #26cf42;
  --grow-trade-bg: #92cfaf;
  --low-trade-bg: #ff7070;
  /* --low-trade-bg: #ffeae3; */
  --inv-aliceblue: #d4e5f4;
  --inv-setting-input: #6c757d;
  --inv-setting-bg: #e7eef8;
  --inv-setting-grey: #e6e6e6;
  --inv-list-color: #9db5d8;
  --inv-section: #f4f6f9;
  --exchange-bg:#fff;
  --trade-border:#dee2e6;
  --inv-nav-white:#fff;
  --red:#e40909;
  --cut-img: url('@/assets/images/icons/close.svg');
--arrow-svg: url('@/assets/images/icons/caret-right.svg');

}

.dark{
   --inv-white: #000;
    --inv-black: #fff;
    --inv-grey:#f7f7f7;
    --inv-primary: #e6ecf2;
    --exchange-bg:#2d2d2d;
     --inv-nav-white:#000;
     --inv-light-grey:#ccc;
}
/* #e6ecf2 */

section {
  padding: 70px 0;
}

label {
  font-size: 15px;
  font-weight: 600;
}

h3 {
  font-size: 18px;
}

input,
select {
  font-size: 14px;
}

.home .card_slider,
.card_slider .swiper {
  height: 110%;
}

.home .card_slider .swiper-pagination-bullet-active {
  background-color: var(--inv-primary);
}

.home .swiper-button-prev:after {
  background-image: url("@/assets/images/home_images/icons/left-arrow.png") !important;
  content: "" !important;
  position: absolute;
  height: 70px;
  width: 70px;
  left: -17px;
  opacity: 0.4;
  filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15));
}

.home .swiper-button-next:after {
  background-image: url("@/assets/images/home_images/icons/right-arrow.png") !important;
  content: "" !important;
  position: absolute;
  height: 70px;
  width: 70px;
  right: -17px;
  opacity: 0.4;
  filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15));
}

/**************** common Css ****************/

.invention-exchange p,
.p2p-box p {
  font-size: 14px;
  font-weight: 700;
}

.exchange-box ul li,
.exchange-box,
.exchange-box h6,
.p2p-box,
.p2p-box ul li,
.p2p-box h6 {
  font-size: 12px;
  font-weight: 600;
}

.exchange-box {
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  overflow: hidden;
  background-color: var(--inv-white);
  min-height: 334px;
  /* min-height: 368px; */
  
}
/**************** Common Form Control  Css ****************/

.invention-exchange .nav-pills .nav-link.active,
.invention-exchange .nav-pills .show > .nav-link,
.p2p-box .nav-pills .nav-link.active,
.p2p-box .nav-pills .show > .nav-link {
  background-color: var(--inv-primary);
  border-radius: 0;
  box-shadow: var(--inv-pills-shadow);

  color: var(--inv-nav-white);
}

.invention-exchange .nav-pills .nav-link,
.p2p-box .nav-pills .nav-link {
  border-radius: 0;
}

.invention-exchange .nav-link,
.p2p-box .nav-link {
  color: var(--inv-black);
  padding: 10px 18px;
  font-weight: 500;
}

.invention-exchange .nav-link:hover,
.p2p-box .nav-link:hover {
  color: var(--inv-black);
}

/*************** ScrollBar Css ***************/

::-webkit-scrollbar {
  background: transparent;
  width: 3px;
  height: 5px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--inv-grey);
  border: solid rgba(0, 0, 0, 0) 2px;
  transition: all 0.4s ease;
}

.crypto_box {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--inv-white);
}
/*****************skeltor loader css */

.vue-skeletor {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
}

.vue-skeletor:not(.vue-skeletor--shimmerless):after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(37, 22, 22, 0)
  );
  animation: shimmer 1.5s infinite;
  content: "";
}

.vue-skeletor--rect,
.vue-skeletor--circle {
  display: block;
}

.vue-skeletor--circle {
  border-radius: 50%;
}

.vue-skeletor--pill,
.vue-skeletor--text {
  border-radius: 9999px;
}

.vue-skeletor--text {
  line-height: 1;
  display: inline-block;
  width: 100%;
  height: inherit;
  vertical-align: middle;
  top: -1px;
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
/*****************skeltor loader css */

.error {
  color: red;
  font-size: 13px;
  font-weight: 600;
}


/* .modal-backdrop.show {
    display: none;
} */
@media only screen and (max-width: 900px) {
  .invention-exchange .nav-link,
  .p2p-box .nav-link {
    padding: 10px 8px;
  }
}

@media all and (min-width: 1200px) and (max-width: 1600px) {
  .invention-exchange .nav-link,
  .p2p-box .nav-link {
    padding: 8px 8px;
  }
}
</style>
